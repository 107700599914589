import {
    internalDelete,
    internalPost,
    internalPut,
} from "../common/http/httpServices";
import useSWRInfinite from "swr/infinite";

const fetcher = (key: string) => fetch(key).then((res) => res.json());

export async function generateReport(payload) {
    try {
        const init = {
            url: "/api/briefer/create",
            body: payload,
        };

        const { response, data } = await internalPost(init);

        let jsonResponse;
        // if data is null, the response body hasn't been cosumed yet, do so
        if (data === null) {
            jsonResponse = await response.json();
        } else {
            jsonResponse = data;
        }
        return jsonResponse;
    } catch (e) {
        console.error("Invalid API response:", e);
    }
}

export async function updateBrief(payload) {
    try {
        const init = {
            url: "/api/briefer/update",
            body: payload,
        };

        const { response, data } = await internalPut(init);
        let jsonResponse;
        // if data is null, the response body hasn't been cosumed yet, do so
        if (data === null) {
            jsonResponse = await response.json();
        } else {
            jsonResponse = data;
        }
        return jsonResponse;
    } catch (e) {
        console.error("Invalid API response:", e);
    }
}

export async function sendReport(payload) {
    try {
        const init = {
            url: "/api/briefer/sendReport",
            body: payload,
        };

        const { response, data } = await internalPost(init);
        let jsonResponse;
        // if data is null, the response body hasn't been cosumed yet, do so
        if (data === null) {
            jsonResponse = await response.json();
        } else {
            jsonResponse = data;
        }
        return jsonResponse;
    } catch (e) {
        console.error("Invalid API response:", e);
    }
}

export async function deleteBrief(id) {
    try {
        const init = {
            url: `/api/briefer/delete?id=${id}`,
        };

        const { response } = await internalDelete(init);

        if (response?.status < 300) {
            return true;
        }

        return false;
    } catch (e) {
        console.error("Invalid API response:", e);
    }
}

export async function fetchBriefs(offSet: number = 0, pageSize = 10) {
    try {
        const response = await fetch(`/api/briefer?offSet=${offSet}&take=${pageSize}`);
        const briefs = await response.json();
        return briefs;
    } catch (e) {
        console.error("Invalid API response:", e);
    }
}


export interface GlobalFeedFilterParams {
    confidenceLevels: string; // Comma separated
    categories: string; // Comma separated
    locations: string; // Comma separated
    keywords: string; // Comma separated
    offset?: number;
    take?: number;
    isInitialLoad?: boolean;
    from?: Date | null;
    to?: Date | null;
}


export async function searchGlobalFeeds({confidenceLevels, categories, locations, keywords, offset = 0, take = 10, isInitialLoad = false, from = null, to = null}: GlobalFeedFilterParams) {
    try {
        let url = `/api/briefer/globalFeedsSearching?confidenceLevels=${confidenceLevels}&categories=${categories}&locations=${locations}&keywords=${keywords}&offSet=${offset}&take=${take}&isInitialLoad=${isInitialLoad}`;

        url += `&from=${from ? from.toISOString() : new Date(null).toISOString()}`;
        url += `&to=${to ? to.toISOString() : new Date().toISOString()}`;

        const response = await fetch(url);
        return await response.json();
    } catch (e) {
        console.error("Invalid API response:", e);
    }
}

export async function get(id) {
    try {
        let url = `/api/briefer/get?id=${id}`;
        const response = await fetch(url);
        const briefs = await response.json();
        return briefs;
    } catch (e) {
        console.error("Invalid API response:", e);
    }
}


export async function countBriefs() {
    try {
        const response = await fetch(`/api/briefer/count`);
        const json = await response.json();
        return json.count;
    } catch (e) {
        console.error("Invalid API response:", e);
    }
}

export async function fetchCategories() {
    try {
        const response = await fetch(`/api/briefer/categories`);
        const categories = await response.json();
        return categories;
    } catch (e) {
        console.error("Invalid API response:", e);
    }
}

const pageSize = 15;
export function useGlobalFeeds({
  refreshInterval = 0,
  confidenceLevels = "",
  categories = "",
  locations = "",
  keywords = ""
}) {
  const { data, mutate, size, setSize, isValidating, isLoading, error } =
    useSWRInfinite(
      (index) => {
        const offSet = index * pageSize;
        let apiUrl = `/api/briefer/globalFeedsSearching?confidenceLevels=${confidenceLevels}&categories=${categories}&locations=${locations}&keywords=${keywords}&offSet=${offSet}&take=${pageSize}`;
        return apiUrl;
      },

      fetcher,
      {
        revalidateOnFocus: false,
        refreshInterval,
      }
    );

  const briefs = data ? data.flatMap((item) => item.briefs) : [];
  const hasMoreResults = data ? data[data.length - 1]?.moreResults : false;
  const isReachingEnd = !hasMoreResults;

  return {
    data: briefs,
    isLoading,
    isError: error,
    isReachingEnd,
    size,
    pageSize: pageSize,
    setSize,
  };
}
